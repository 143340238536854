import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    filters: {
      location: [],
      commerceType: [],
    },
    authenticated:
      localStorage.getItem("token") !== null &&
      localStorage.getItem("token") !== undefined,
    querySearch: "",
    currentUser: {},
    userImage: require("@/assets/icons/edit-user-icon.png"), // Imagen por defecto del usuario
    defaultUserImage: require("@/assets/icons/edit-user-icon.png"),
  },
  mutations: {
    resetUserImage(state) {
      state.userImage = state.defaultUserImage; // Resetea la imagen al valor por defecto
    },

    addLocationFilter(state, payload) {
      state.filters.location.push(payload);
    },
    addCommerceTypeFilter(state, payload) {
      state.filters.commerceType.push(payload);
    },
    removeLocationFilter(state, payload) {
      state.filters.location = state.filters.location.filter(
        (item) => item !== payload
      );
    },
    removeCommerceTypeFilter(state, payload) {
      state.filters.commerceType = state.filters.commerceType.filter(
        (item) => item !== payload
      );
    },
    setAuthenticated(state, payload) {
      state.authenticated = payload;
    },
    setQuerySearch(state, payload) {
      state.querySearch = payload;
    },
    setCurrentUser(state, payload) {
      state.currentUser = payload;
    },
    setUserImage(state, image) {
      state.userImage = image;
    },
  },
  actions: {
    addLocationFilter({ commit }, payload) {
      commit("addLocationFilter", payload);
    },
    addCommerceTypeFilter({ commit }, payload) {
      commit("addCommerceTypeFilter", payload);
    },
    removeLocationFilter({ commit }, payload) {
      commit("removeLocationFilter", payload);
    },
    removeCommerceTypeFilter({ commit }, payload) {
      commit("removeCommerceTypeFilter", payload);
    },
    setAuthenticatedAction({ commit }, payload) {
      commit("setAuthenticated", payload);
    },
    setQuerySearchAction({ commit }, payload) {
      commit("setQuerySearch", payload);
    },
    async checkTokenValidity({ commit }) {
      try {
        const response = await axios.get(
          `/api/usuarios/valid/${localStorage.getItem("token")}`
        );
        if (response.data.status) {
          commit("setAuthenticated", true);
          commit("setCurrentUser", response.data.data);
          // Aquí podrías llamar a setUserImage si necesitas actualizar la imagen al iniciar sesión
        } else {
          commit("setAuthenticated", false);
          commit("setCurrentUser", {});
          commit("setUserImage", require("@/assets/icons/edit-user-icon.png")); // Establecer imagen por defecto
          localStorage.removeItem("token");
        }
      } catch (error) {
        console.error("Error al validar token:", error);
        commit("setAuthenticated", false);
        commit("setCurrentUser", {});
        commit("setUserImage", require("@/assets/icons/edit-user-icon.png")); // Establecer imagen por defecto
        localStorage.removeItem("token");
      }
    },
    setCurrentUserAction({ commit }, payload) {
      commit("setCurrentUser", payload);
    },
    
    async getUserImage({ commit, state }) {

        
        if (!state.authenticated) {
          return; // Salir de la acción si el usuario no está autenticado
        }
    
        try {
          const response = await axios.get(
            `/api/usuarios/${localStorage.getItem("token")}/imagen`,
            { responseType: "blob" }
          );
    
          if (response.status === 200 && response.data instanceof Blob) {
            const reader = new FileReader();
            reader.onloadend = () => {
              commit("setUserImage", reader.result);
            };
            reader.readAsDataURL(response.data);
          } else {
            console.error("Error: No se pudo obtener la imagen");
            commit("setUserImage", require("@/assets/icons/edit-user-icon.png")); // Usar la imagen por defecto en caso de error
          }
        } catch (error) {
          console.error("Error al obtener la imagen", error);
          commit("setUserImage", require("@/assets/icons/edit-user-icon.png")); // Usar la imagen por defecto en caso de error
        }
      },
    
    
  },
  getters: {
    getLocationFilters(state) {
      return state.filters.location;
    },
    getCommerceTypeFilters(state) {
      return state.filters.commerceType;
    },
 
    getQuerySearch(state) {
      return state.querySearch;
    },
    getCurrentUser(state) {
      return state.currentUser;
    },
    getUserImage(state) {
      return state.userImage;
    },

    userImage: (state) => state.userImage,
    isAuthenticated: (state) => state.authenticated,
  },
});
